import React, { useEffect, useState } from "react";
import Dcards from "./Dcard";
import "./Products.css";
import Sdata from "./Sdata";
import Api from "../../Api/Api";
import { useLocation, useParams } from 'react-router-dom';

const AllItem = () => {
  const [books, setBooks] = useState([]);
  const [items, setItems] = useState(books);
  const location = useLocation();
  const { id } = location.state || {};
  let { category } = useParams();
  const [subcategory, setSubcategory] = useState([]);
  const [activeCategory, setActiveCategory] = useState('All');

  const filterItems = (subcategoryId, subcategoryname) => {
    if (subcategoryname === "All") {
      setItems(books);
    } else {
      const filteredItems = books.filter(item => item.subCategoryById === subcategoryId);
      setItems(filteredItems);
    }
    setActiveCategory(subcategoryname);
  };

  useEffect(() => {
    Api.subcategorylist(id).then(res => {
      if (res.status === "Success") {
        setSubcategory(res.data);
      }
    });
  }, [id]);

  useEffect(() => {
    Api.bookslist(id).then(res => {
      if (res.status === "Success") {
        setBooks(res.data);
        setItems(res.data);
      }
    });
  }, [id]);

  let categoryname = category.charAt(0).toUpperCase() + category.slice(1);
  
  return (
    <>
      <section className='gallery desi mtop'>
        <div className="producttitle">
          <h3 className="mb-4">{categoryname}</h3>
          <div className="categories">
            <button className={`btn ${activeCategory === "All" && "active"} mb-2`} onClick={() => filterItems("", "All")}>All</button>
            {subcategory.map(item => (
              <button className={`btn ${activeCategory === item.subCategoryName && "active"} mb-2`} key={item.subCategoryName} onClick={() => filterItems(item._id, item.subCategoryName)}>
                {item.subCategoryName}
              </button>
            ))}
          </div>
        </div>
        <div className='container'>
          <div className='content grid'>
            {items.length > 0 && items.map((item, ind) => {
              return <Dcards key={ind} item={item} categoryname={category} />;
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default AllItem;
