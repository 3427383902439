// utils.js
import { useState, useEffect } from 'react';
export const slugify = (text) =>
  text
    .toString()
    .toLowerCase()
    .trim()
    // .replace(/\s+/g, '-') // Replace spaces with dashes
    .replace(/\s+/g, '') // Replace spaces with dashes
    .replace(/[^\w-]+/g, ''); // Remove non-word characters except dashes


    export  const useIsMobile = () => {
      const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
      useEffect(() => {
          const handleResize = () => {
              setIsMobile(window.innerWidth <= 768);
          };
          window.addEventListener('resize', handleResize);
  
          return () => {
              window.removeEventListener('resize', handleResize);
          };
      }, []);
  
      return isMobile;
  };
  
  