import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Box,
    Menu,
    MenuItem,
    Avatar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { Link,useNavigate } from 'react-router-dom';
import logo from '../../images/logo.jpg';
import Api from '../../Api/Api';
import { logout } from '../../Store/Slice/AuthSlice';
import { slugify } from "../../utils/utils";
const Header = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const [categories, setCategories] = useState([]);
    const navigate =useNavigate()

    // Use useSelector to access the auth state and check if the user is logged in
    const isLoggedIn = useSelector((state) => state.auth.auth);
    const data = useSelector((state) => state.cartdata.cartdata);
    const user = useSelector((state) => state.auth.user); // Access user information (e.g. name and avatar URL)
  
    useEffect(() => {
        Api.categorylist().then(res => {
            if (res.status === 'Success') {
                setCategories(res.data);
            }
        });
    }, []);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (category) => {
       
        navigate(
          `/book/${slugify(category.categoryName)}`,
            {
                state: { id: category._id }
            }
        )
        console.log("category",category)
        setAnchorEl(null);
        
    };

    const handleLogout = () => {
        localStorage.clear();
        dispatch(logout());
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: '#3f51b5' /* Change the background color as needed */ }}>
            <Toolbar>
                {/* For smaller screens, show a hamburger menu button */}
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer}
                    sx={{ display: { xs: 'flex', md: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>

                {/* Brand logo image */}
                <Box sx={{ flexGrow: 1 }}>
                    <Link to="/">
                        <img src={logo} alt="Brand Logo" style={{ width: '50px', height: '50px' }} />
                    </Link>
                </Box>

                {/* Menu items and buttons */}
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Button color="inherit" component={Link} to="/">
                        Home
                    </Button>

                    {/* Category dropdown menu */}
                    <Button color="inherit" onClick={handleMenuClick}>
                        Categories
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        {categories.map((category, index) => (
                           
                            <MenuItem
                                key={index}
                                // component={Link}
                                
                                // to={{
                                //     pathname: `/book/${slugify(category.categoryName)}`,
                                //     // state: { id: category._id },
                                //     state:{"id":'nikhil'}
                                //   }}
                                onClick={()=>handleMenuClose(category)}
                            >
                                {category.categoryName}
                            </MenuItem>
                        ))}
                    </Menu>

                    {/* Add to Cart button */}
               {data?.Products.length > 0 &&
                    <Button
                        color="inherit"
                        component={Link}
                        to="/cart"
                        startIcon={<ShoppingCartIcon />}
                    >
                        Add to Cart
                    </Button>}

                    {/* Conditionally render login or logout button */}
                    {isLoggedIn ? (
                        <Button
                            color="inherit"
                            onClick={handleLogout}
                            startIcon={<LogoutIcon />}
                        >
                            Logout
                        </Button>
                    ) : (
                        <Button color="inherit" component={Link} to="/login">
                            Login
                        </Button>
                    )}
                </Box>

                {/* Drawer for mobile menu */}
                <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer}>
                    <List sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box>
                            <IconButton onClick={toggleDrawer}>
                                <CloseIcon />
                            </IconButton>
                            <ListItem button component={Link} to="/">
                                <ListItemText primary="Home" />
                            </ListItem>
                            {categories.map((category, index) => (
                                <ListItem button key={index} component={Link} to={{
                                    pathname: `/book/${slugify(category.categoryName)}`,
                                    state: {
                                        id: category._id,
                                    },
                                }}  >
                                    <ListItemText primary={category.categoryName} />
                                </ListItem>
                            ))}
                            <ListItem button component={Link} to="/cart">
                                <ListItemText primary="Cart" />
                            </ListItem>
                        </Box>

                        {/* Footer section in the drawer */}
                        <Box sx={{ padding: '8px', borderTop: '1px solid #ccc' }}>
                            {/* User avatar and name */}
                            {isLoggedIn && (
                                <Box display="flex" alignItems="center" mb={2}>
                                    <Avatar alt={user?.name} src={user?.avatarUrl} nam sx={{ marginRight: 1 }} />
                                    <span>{"Nikhilesh Dubey"}</span>
                                </Box>
                            )}
                            <hr style={{ margin: '8px 0' }} /> {/* Indicator line */}
                            {isLoggedIn && (
                                <ListItem button onClick={handleLogout}>
                                    <ListItemText primary="Logout" />
                                    <LogoutIcon sx={{ marginLeft: 1 }} />
                                </ListItem>
                            )}
                        </Box>
                    </List>
                </Drawer>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
