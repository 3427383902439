import React, { useState, useEffect } from "react";
import EmptyFile from "../../common/Empty/EmptyFile";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import "./ProductDetails.css";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Api from "../../Api/Api";
import { addtocart } from "../../Store/Slice/Cartslice";
import defaultImage from '../../images/noImage-found.jpg';
import Swal from "sweetalert2"; // Import SweetAlert

const ProductDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = location?.state?.data;
  const aadata = useSelector((state) => state.cartdata.cartdata);
  const userdata = useSelector((state) => state.auth.user);
console.log("data",data)
  const cartadd = async (product) => {
    if (!userdata) {
      Swal.fire({
        title: "Please login!",
        text: "You must be logged in to add items to the cart.",
        icon: "warning",
        confirmButtonText: "Login",
      }).then(() => {
        navigate("/login"); // Redirect to login page
      });
      return;
    }

    try {
      const slicedata = { 
        ...aadata, 
        CustomerEmail: userdata?.email, 
        CustomerFirstName: userdata?.firstname, 
        CustomerMobileNumber: userdata?.phoneNO 
      };

      const addproduct = {
        ProductID: product?._id,
        ProductImage: product?.ProductImage,
        ProductName: product?.ProductName,
        ProductPrice: product?.ProductPrice,
        Qty: 1,
        SubTotal: 0,
        Amount: 0,
      };

      slicedata.Products = slicedata.Products.map((product) => ({ ...product }));
      const existingProductIndex = slicedata.Products.findIndex(
        (prod) => prod.ProductID === addproduct.ProductID
      );

      if (existingProductIndex !== -1) {
        slicedata.Products[existingProductIndex] = {
          ...slicedata.Products[existingProductIndex],
          Qty: slicedata.Products[existingProductIndex].Qty + 1,
        };
      } else {
        slicedata.Products.push(addproduct);
      }

      const res = await Api.cart(slicedata);
      if (res.code == 0) {
        const resdata = res.data;
        const keysToRemove = ["_v", "_id", "createdAt", "updatedAt"];
        const modifiedCart = Object.fromEntries(
          Object.entries(resdata).filter(([key]) => !keysToRemove.includes(key))
        );
        dispatch(addtocart({ datacart: modifiedCart }));
        toast.success("Item successfully added to your cart");
        navigate("/cart");
      } else {
        toast.error("Oops! Something went wrong");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("Oops! Something went wrong");
    }
  };

  return (
    <>
      {data ? (
        <section className="single-page top">
          <div className="container">
            <article className="content flex">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <img
                    src={data?.ProductImage}
                    alt={data?.ProductName}
                    className="img-fluid"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultImage;
                    }}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <div className="side-content">
                    {/* Product Name - Larger and Bolder */}
                    <h2>{data?.ProductName}</h2>
  
                    {/* Author and other details - Normal styling */}
                    <p>
                      <strong>Author:</strong> {data?.AuthorName}
                    </p>
                    <p>
                      <strong>Price:</strong> ₹{data?.ProductPrice}
                    </p>
                    <p>
                      <strong>Page Number:</strong> {data?.PageNumber}
                    </p>
                    <p>
                      <strong>Paperback Amount:</strong> ₹{data?.PaperbackAmount}
                    </p>
                    <p>
                      <strong>Discount:</strong> {data?.Discountpercentage}%
                    </p>
                    <p>
                      <strong>Refundable:</strong>{" "}
                      {data?.Refundable ? "Yes" : "No"}
                    </p>
                    <p>
                      <strong>Returnable:</strong>{" "}
                      {data?.Returnable ? "Yes" : "No"}
                    </p>
  
                    {/* Product Description - Slightly larger and emphasized */}
                    <p className="description" dangerouslySetInnerHTML={{ __html: data?.productDescription }}></p>
  
                    {/* Add to Cart Button */}
                    <button
                      // className="cart-btn mt-3"
                      className="cart-btn mt-3"
                      onClick={() => cartadd(data)}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </section>
      ) : (
        <EmptyFile />
      )}
    </>
  );
  
};

export default ProductDetails;
